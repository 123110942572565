<script>
import NativePerfArticleNew from './NativePerfArticleNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'NativePerfArticleEdit',
  extends: NativePerfArticleNew,
  methods: {
    getItem () {
      this.$store.dispatch('nativePerfArticle/fetchOne', this.$route.params.id)
        .then(() => {
          this.item = this.$store.getters['nativePerfArticle/detail']

          this.paused = this.item.status === 4

          if (this.item.alternativesEnabled) {
            this.loadArticleAlternatives()
          } else {
            this.fetchMainImage(this.item.damImageId)
          }

          if (this.item.schedulingEnabled) {
            this.setSchedulingConfig()
          }
        })
    },

    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }

      if (!this.validateMainImage()) {
        return
      }

      if (!this.validateAlternatives()) {
        return
      }

      if (this.item.schedulingEnabled && !this.validateSchedulingDeliveryTime()) {
        return
      }

      this.$store.dispatch('nativePerfArticle/update', this.prepareRequest(this.item))
        .then(() => {
          this.item = this.$store.getters['nativePerfArticle/detail']
          if (this.$store.getters['nativePerfArticle/error'] === null) {
            if (this.item.alternativesEnabled) {
              this.getItem()
            }

            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            const status = this.$store.getters['nativePerfArticle/error'].status

            if (status === 400) {
              const validationErrors = this.$store.getters['nativePerfArticle/error'].validationErrors

              for (const error of validationErrors) {
                const detail = error.detail

                if (this.item.alternativesEnabled) {
                  const alternative = error.alternative
                  NotifyService.setErrorMessage(this.$t('don.npa.validation.alternative_' + detail) + ' ' + alternative + '.')
                } else {
                  NotifyService.setErrorMessage(this.$t('don.npa.validation.' + detail))
                }
              }
            } else {
              NotifyService.setErrorMessage(this.$store.getters['nativePerfArticle/error'])
            }
          }
        })
        .catch(error => console.log(error))
    }
  },

  created () {
    this.mode = 'update'
    this.dataLoaded = false
    this.getItem()
  }
}
</script>
